import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ImageGiftFreeCryptocurrency = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gift-free-cryptocurrency.png" }) {
        childImageSharp {
          fluid(maxWidth: 2150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      placeholderClassName="hidden"
      alt="Get & Gift Free Cryptocurrency"
      style={{ width: "182px", display: 'inline-block', margin: '62px 0' }}
    />
  )
}

export default ImageGiftFreeCryptocurrency

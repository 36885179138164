import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import GiftIntro from "../components/gift/GiftIntro/GiftIntro"
import GiftExplanation from "../components/gift/GiftExplanation/GiftExplanation"

const GiftPage = () => (
  <Layout>
    <SEO
      title="Enter the twenties with CoinCollect 💫"
      description="Install CoinCollect. Get a gift. It could not be easier."
    />

    <GiftIntro />
    <GiftExplanation />

    <div class="text-center" style={{
      margin: '26px auto',
      color: '#323232',
      fontSize: '16px',
      maxWidth: '90%',
      opacity: 0.5,
    }}>
      Terms & conditions:<br />
      Only for new installs. The first 1000 installs get free cryptocurrency on their wallet. 
    </div>

    <div class="text-center" style={{
      margin: '52px auto',
      color: '#323232',
      fontSize: '20px',
      lineHeight: '25px',
      maxWidth: '90%',
    }}>
      <p>
        Questions about this promotion?
      </p>
      <p>
        <a href="https://discordapp.com/invite/n7fAKJE" target="_blank" class="target-link" style={{
          margin: '9px auto',
          display: 'block',
        }}>
          Talk to us on Discord
        </a>
      </p>
    </div>

  </Layout>
)

export default GiftPage

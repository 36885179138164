import React, { Component } from "react"
import Wrap from "../../wrap"

import ImageGiftFreeCryptocurrency from "../../../components/images/gift-free-cryptocurrency"
import DownloadButtons from "../../../components/download-buttons"
import giftPhoneMockup from "../../../images/gift-phone-mockup.png"

class GiftExplanation extends Component {
  render() {
    return (
      <section className="p-5 lg:px-0 lg:py-32 text-black" style={{
            backgroundColor: '#f4faff',
            backgroundImage: `url(${giftPhoneMockup})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 100%',
            paddingBottom: '360px',
            backgroundSize: '291px'
          }}>
        <Wrap paddingY="0">
          <div className="">

            <h1 className="GiftExplanation-title mb-2 font-bold text-theme-gray-333333 text-center">
              How does it work?
            </h1>

            <h2 className="GiftExplanation-subtitle font-normal">
              Install CoinCollect on <a href="" className="target-link">iOS</a> or <a href="" className="target-link">Android</a>.
            </h2>

            <h2 className="GiftExplanation-subtitle font-normal">
              Get between €1 and €5 Bitcoin (BTC) loaded into your wallet.
            </h2>

            <h2 className="GiftExplanation-subtitle font-normal">
              Gift to friends & family. Get them to install CoinCollect and let them join the cryptocurrency revolution.
            </h2>

          </div>
        </Wrap>
      </section>
    )
  }
}

export default GiftExplanation

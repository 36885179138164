import React, { Component } from "react"
import Wrap from "../../wrap"

import ImageGiftFreeCryptocurrency from "../../../components/images/gift-free-cryptocurrency"
import DownloadButtons from "../../../components/download-buttons"
import giftPackages from "../../../images/gift-packages.png"

class GiftIntro extends Component {
  render() {
    return (
      <section className="
        p-5
        lg:px-0
        lg:py-32
        text-black
        bg-center
        bg-cover
        bg-norepeat
        GiftIntro-background
      " style={{
            backgroundImage: `url(${giftPackages})`,
          }}>
        <Wrap paddingY="0">
          <div className="">

            <div className="text-center">
              <ImageGiftFreeCryptocurrency />
            </div>

            <h1 className="CoinPage-title mb-2 font-bold text-theme-gray-333333 text-center">
              Claim Your Gift
            </h1>

            <h2 className="CoinPage-subtitle font-normal text-center">
              Install CoinCollect. Get a gift.<br />
              It could not be easier.
            </h2>

            <div className="mt-12 mb-20">
              <DownloadButtons />
            </div>

          </div>
        </Wrap>
      </section>
    )
  }
}

export default GiftIntro
